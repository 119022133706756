<template>
  <div class="app-container">
    <div class="top">
      <div v-if="detail.status === 'WAIT_PAY'" class="top__wait">
        <h3 class="top__title top__wait--title">{{ detail.statusName }}</h3>
        <p class="top__wait--time">
          <i class="iconfont icon-dingdanchaxun_zhigongguangwang"></i>
          剩余:
          <OrderTimeClock :date="detail.expireTime" :ref="'time_' + detail.id"></OrderTimeClock>
        </p>
        <div class="top__wait--btn-wrap">
          <CancelOrder @cancelSuccess="cancelSuccess" :id="$route.params.id"></CancelOrder>
          <el-button
            @click="linkPay()"
            class="top__wait--btn red"
            v-if="detail.status === 'WAIT_PAY'"
            >付款</el-button
          >
        </div>
      </div>
      <div v-else>
        <h3 class="top__title">{{ detail.statusName }}</h3>
        <p class="top__text" v-if="detail.status === 'REFUNDED'">订单全部退款完成</p>
        <p class="top__text" v-if="detail.status === 'CANCELED'">
          {{ detail.remarks ? detail.remarks : '买家取消订单' }}
        </p>
        <div class="top__btn">
          <el-button
            class="top__btn--item bgRed"
            v-if="detail.status === 'PAID' || detail.status === 'SUB_DELIVERY'"
            @click="remind"
            >提醒发货</el-button
          >
          <el-button
            class="top__btn--item bgRed"
            v-if="detail.status === 'DELIVERED'"
            @click="orderReceived"
            >确认收货</el-button
          >
          <CheckDeliveryDetail
            :id="$route.params.id"
            v-if="
              detail.status === 'SUB_DELIVERY' ||
              detail.status === 'DELIVERED' ||
              detail.status === 'FINISHED'
            "
          >
            <el-button class="top__btn--item"> 查看物流 </el-button>
          </CheckDeliveryDetail>
          <el-button
            class="top__btn--item"
            @click="buyAgain"
            v-if="
              detail.orderSource !== 'CREDIT_EXCHANGE' &&
              detail.orderSource !== 'YEARLY_RECOMMEND_PLAN'
            "
            >加入购物车</el-button
          >
          <DialogRefund
            :orderId="$route.params.id"
            :goodList="detail.orderDetailVOList"
            :status="detail.status"
            :type="detail.type"
            @handClickRefund="handClickRefund"
            v-if="dialogRefundShow"
          />
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="detail__box">
        <div class="detail__box--title">基本信息</div>
        <div class="detail__box--content">
          <p class="detail__box--item">订单类型：{{ detail.typeName }}</p>
          <p class="detail__box--item">
            订单编号： {{ detail.orderNo }}
            <span
              class="detail__box--btn"
              v-clipboard:copy="detail.orderNo"
              v-clipboard:error="onError"
              v-clipboard:success="copyOrderNo"
              >复制</span
            >
          </p>
          <p class="detail__box--item">
            交易单号： {{ detail.transactionNo ? detail.transactionNo : '/' }}
            <span
              v-if="detail.transactionNo"
              class="detail__box--btn"
              v-clipboard:copy="detail.transactionNo"
              v-clipboard:error="onError"
              v-clipboard:success="copyTransactionNo"
              >复制</span
            >
          </p>
          <div class="detail__box--box">
            <p class="detail__box--label">买家信息：</p>
            <p class="detail__box--value">
              {{ detail.buyerMobile
              }}<span class="detail__box--name">{{ detail.buyerNickName }}</span>
            </p>
          </div>
          <p class="detail__box--item">创建方式：{{ detail.orderSaleTypeName }}</p>
          <p class="detail__box--item">下单端口：{{ detail.orderChannelName }}</p>
          <p class="detail__box--item">
            买家订单号：{{ detail.buyerOrderNo ? detail.buyerOrderNo : '/' }}
            <span
              v-if="detail.buyerOrderNo"
              class="detail__box--btn"
              v-clipboard:copy="detail.buyerOrderNo"
              v-clipboard:error="onError"
              v-clipboard:success="copyBuyerOrderNo"
              >复制</span
            >
          </p>
          <p class="detail__box--item">
            下单时间：{{ detail.createDate | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
          </p>
          <p class="detail__box--item" v-if="detail.contractInfo">
            合同主体：{{
              detail.contractInfo.merchantTypeName + '-' + detail.contractInfo.contractInfoName
            }}
          </p>
          <div v-if="detail.orderGlobal">
            <p class="detail__box--item">订货人姓名：{{ detail.orderGlobal.buyerName }}</p>
            <p class="detail__box--item">
              订货人身份证号：{{ detail.orderGlobal.idcardNo }}
              <span v-if="detail && detail.orderGlobal && detail.orderGlobal.isSamePay === '0'">
                报关失败
                <MemberManagement @sureRefresh="sureRefresh" id="afresh-edit" ref="customs">
                  <span @click="$refs.customs.show(detail.id)" class="red pointer">修改</span>
                </MemberManagement>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="detail__box">
        <div class="detail__box--title">配送信息</div>
        <div class="detail__box--content">
          <div class="detail__box--box">
            <p class="detail__box--label">收货信息：</p>
            <p class="detail__box--value">
              {{ areaName }}
            </p>
          </div>
          <div class="detail__box--box">
            <p class="detail__box--label">买家留言：</p>
            <p class="detail__box--value">{{ detail.buyerNote ? detail.buyerNote : '/' }}</p>
          </div>
          <p class="detail__box--item">配送方式：快递配送</p>
        </div>
      </div>
      <div class="detail__box">
        <div class="detail__box--title">支付信息</div>
        <div class="detail__box--content">
          <p class="detail__box--item">商品总价：￥{{ detail.saleAmount }}</p>
          <p class="detail__box--item">运费：¥{{ detail.deliveryFee }}</p>
          <p class="detail__box--item">
            优惠金额：¥{{ detail.promotionAmount }}
            <span @click="dialogVisible = true" class="detail__box--discount">优惠明细</span>
          </p>
          <p class="detail__box--item">实收金额：¥{{ detail.receiptAmount }}</p>
          <p class="detail__box--item">
            付款时间：<span v-if="detail.payTime">{{
              detail.payTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}')
            }}</span
            ><span v-else>/</span>
          </p>
          <p class="detail__box--item">
            支付方式：{{ detail.payTypeName ? detail.payTypeName : '/' }}
          </p>
          <p class="detail__box--item">
            支付流水号：
            {{
              detail.orderPaymentVO && detail.orderPaymentVO.tradeNo
                ? detail.orderPaymentVO.tradeNo
                : '/'
            }}
            <span
              v-if="detail.orderPaymentVO && detail.orderPaymentVO.tradeNo"
              class="detail__box--btn"
              v-clipboard:copy="detail.orderPaymentVO ? detail.orderPaymentVO.tradeNo : ''"
              v-clipboard:error="onError"
              v-clipboard:success="copyTradeNo"
              >复制</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="detail.orderDetailVOList" style="width: 100%" v-loading.body="listLoading">
        <el-table-column label="商品信息" width="300px" header-align="center">
          <template slot-scope="scope">
            <div class="table__name">
              <router-link
                :to="
                  detail.orderSource === 'YEARLY_RECOMMEND_PLAN'
                    ? ''
                    : '/syoung/commodity/goods-detail/' + scope.row.commodityId
                "
              >
                <img :src="scope.row.imgUrl" class="table__name--img" />
                <p class="table__name--name">{{ scope.row.commodityName }}</p>
                <span class="table__name--rebate" v-if="scope.row.isBackVirtualCredit === '1'">
                  返利
                </span>
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="规格信息" width="200px">
          <template slot-scope="scope">
            <p>{{ scope.row.commoditySpecValue }}</p>
            <p>规格标识: {{ scope.row.skuId }}</p>
          </template>
        </el-table-column>
        <el-table-column label="单价" align="center">
          <template slot-scope="scope">￥{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column label="数量" prop="quantity" align="center"> </el-table-column>
        <el-table-column label="赠品/套装" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.isGift === '1'">赠品</p>
            <p v-if="scope.row.isSet === '1'">套装</p>
            <p v-if="scope.row.isGift !== '1' && scope.row.isSet !== '1'">/</p>
          </template>
        </el-table-column>
        <el-table-column label="优惠金额" align="center">
          <template slot-scope="scope">￥{{ scope.row.promotionAmount }}</template>
        </el-table-column>
        <el-table-column label="实收金额" align="center">
          <template slot-scope="scope">￥{{ scope.row.receiptAmount }}</template>
        </el-table-column>
        <el-table-column label="发货状态" align="center">
          <template slot-scope="scope">
            {{ scope.row.deliveryStatusName }}
            <!-- 部分发货需要显示发货数量 -->
            <span v-if="scope.row.deliveryStatus === 'SUB_DELIVERY'"
              >({{ scope.row.deliveryNum }}件)</span
            >
          </template>
        </el-table-column>
        <el-table-column label="是否已退款" align="center">
          <template slot-scope="scope">
            {{ scope.row.refundStatus | filterRefundStatus }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="优惠明细" width="50%">
      <el-table
        :data="detail.orderMarketingDiscountVOList"
        style="width: 100%"
        v-loading.body="listLoading"
      >
        <el-table-column label="优惠类型">
          <template slot-scope="scope">
            <span>{{ scope.row.activityTypeName }}</span>
            <el-tooltip class="item" effect="dark" placement="top" v-if="showTooltip(scope.row.activityType)">
              <div slot="content">
                <p>{{ scope.row.activityTypeName }}-{{scope.row.activityName}}：￥{{scope.row.discountAmount}}</p>
              </div>
              <i class="el-icon-question" style="margin-left: 4px;"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="优惠活动名称">
          <template slot-scope="scope">{{ scope.row.activityName }}</template>
        </el-table-column>
        <el-table-column label="优惠金额" prop="discountAmount"></el-table-column>
      </el-table>

      <span class="dialog-footer" slot="footer">
        <el-button @click="dialogVisible = false" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getById, addToCartInBatch, received } from '@/api/order/inquiry/list';
import OrderTimeClock from '@/components/OrderTimeClock';
import MemberManagement from '@/components/views/checkout/MemberManagement'; // 报关
import CheckDeliveryDetail from '@/components/CheckDeliveryDetail';
import DialogRefund from '@/components/views/order/DialogRefund';
import { mapActions } from 'vuex';
import eventReporter from '@/utils/event-reporter';
import CancelOrder from '@/components/CancelOrder';
export default {
  components: {
    OrderTimeClock,
    MemberManagement,
    CheckDeliveryDetail,
    DialogRefund,
    CancelOrder,
  },
  data() {
    return {
      payType: this.getPayType(), // 支付方式，默认是微信支付,如果用户有切换支付方式则选择用户的支付方式
      detail: { id: null },
      listLoading: true,
      detailLoading: true,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false, // 优惠明细弹出框显示控制
      dialogRefundShow: false,
    };
  },
  mounted() {
    this.fetchDetail();
  },
  computed: {
    areaName() {
      const {
        consignee,
        mobile,
        province = '',
        city = '',
        zone = '',
        addressDetail = '',
      } = this.detail.orderConsigneeAddressVO || {};
      return `${consignee},${mobile},${province}${city}${zone}${addressDetail}`;
    },
  },
  methods: {
    showTooltip(type){
      const whiteList = ['VIRTUAL_CREDIT', 'YEAR_VIRTUAL_CREDIT'];
      return whiteList.includes(type);
    },
    remind() {
      this.$alert('提醒成功');
    },
    // 取消订单之后刷新数据
    cancelSuccess() {
      this.fetchDetail();
    },
    linkPay() {
      if (this.$refs['time_' + this.detail.id].isStop) {
        this.$message.warning('已超出支付时间，请重新下单');
        return;
      }
      const postData = {
        bizType: this.detail.bizType,
        body: '水羊直供',
        id: this.detail.id,
        payAmount: this.detail.payAmount,
        expireTime: this.detail.expireTime,
        customerTel: this.detail.buyerMobile,
        customerIdNo: this.detail.orderGlobal ? this.detail.orderGlobal.idcardNo : '',
        customerName: this.detail.orderGlobal ? this.detail.orderGlobal.buyerName : '',
        goodsCount: this.getGoodsCount(this.detail.orderDetailVOList),
        type: this.detail.type,
        ownCompanyId: this.detail?.ownCompanyId || '',
        brandType: this.detail?.brandType || '',
      };
      sessionStorage.setItem('payData', JSON.stringify(postData));
      this.$router.push(`/syoung/pay/list?type=${this.detail.type}`);
    },
    // 获取支付方式，默认是微信支付,如果用户有切换支付方式则选择用户的支付方式
    getPayType() {
      if (localStorage.getItem('otherPay')) {
        return localStorage.getItem('otherPay') === '微信' ? 'weChat' : 'alipay';
      }
      return 'weChat';
    },
    // 获取订单商品数量
    getGoodsCount(list) {
      let num = 0;
      list.forEach(item => {
        num = item.quantity + num;
      });
      return num;
    },
    fetchDetail() {
      const id = this.$route.params.id;
      this.detailLoading = true;
      getById(id)
        .then(response => {
          this.detail = response.data;
          // 是否能退款 部分发货也能支持退款
          const statusList = ['FINISHED', 'PAID', 'DELIVERED', 'RECEIVED', 'SUB_DELIVERY'];
          // 积分订单 或者 年框订单并且已付款待发货状态  不能退款
          const notCanRefundByOrderSource =
            this.detail.orderSource === 'CREDIT_EXCHANGE' ||
            (this.detail.orderSource === 'YEARLY_RECOMMEND_PLAN' && this.detail.status === 'PAID');
          for (let i = 0; i < statusList.length; i++) {
            if (statusList[i] === this.detail.status && !notCanRefundByOrderSource) {
              this.dialogRefundShow = true;
              break;
            } else {
              this.dialogRefundShow = false;
            }
          }
        })
        .finally(() => {
          this.detailLoading = false;
          this.listLoading = false;
        });
    },
    sureRefresh() {
      this.fetchDetail();
    },
    // 订单编号
    copyOrderNo(e) {
      this.$message.success('订单编号' + e.text + '复制成功');
    },
    // 交易单号
    copyTransactionNo(e) {
      this.$message.success('交易单号' + e.text + '复制成功');
    },
    // 买家订单号
    copyBuyerOrderNo(e) {
      this.$message.success('买家订单号' + e.text + '复制成功');
    },
    // 支付流水号
    copyTradeNo(e) {
      this.$message.success('支付流水号' + e.text + '复制成功');
    },
    onError(e, text = '订单编号') {
      this.$alert(text + '复制失败', '请手动复制', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${text}`,
          });
        },
      });
    },
    // 再次购买
    buyAgain() {
      const requestData = this.detail.orderDetailVOList
        .filter(item => {
          return item.isGift === '0';
        })
        .map(item => {
          if (item.isGift === '0') {
            return { commodityId: item.commodityId, skuId: item.skuId, quantity: item.quantity };
          }
        });
      addToCartInBatch(requestData).then(res => {
        if (res.code === '0') {
          this.$message({
            message: '成功加入采购车',
            type: 'success',
          });
          // 加入购物车埋点
          eventReporter.trackClick(this.$route, {
            name: 'zg_add_goods_click_btn',
            aliasName: '加入购物车',
            query: requestData,
            event_source: 'oder_detail',
          });
          this.$router.push('/syoung/car/list');
          return;
        }
        this.$message({
          message: res.msg,
          type: 'error',
        });
      });
    },
    ...mapActions(['getCountStatisticsHot']),
    // 确认收货
    orderReceived() {
      this.$confirm('是否确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          received(this.$route.params.id).then(res => {
            this.fetchDetail();
            this.getCountStatisticsHot();
            this.$message({
              type: 'success',
              message: '成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 退款
    handClickRefund() {
      this.fetchDetail();
    },
  },
  filters: {
    filterRefundStatus(status) {
      switch (status) {
        case 'NO_REFUND':
          return '否';
        case 'REFUNDING':
          return '退款中';
        case 'REFUNDED':
          return '已退款';
        default:
          return '';
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
  width: 1180px;
  color: #333;
  margin-bottom: 60px;
}

.top {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-top: 4px solid #ab0033;
  margin-bottom: 20px;
  &__wait {
    width: 200px;
    height: 94px;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 1px;
      height: 134px;
      right: 0px;
      top: -20px;
      background-color: #e5e5e5;
    }
    &--title {
      text-align: center;
    }
    &--time {
      color: #ab0033;
      text-align: center;
      margin-top: 6px;
    }
    &--btn-wrap {
      margin-top: 18px;
      display: flex;
    }
    &--btn {
      min-width: 80px;
      font-size: 12px;
      margin-left: 16px;
    }
  }
  &__title {
    color: #ab0033;
    font-size: 20px;
    line-height: 30px;
  }
  &__text {
    color: #666;
    line-height: 18px;
  }
  &__btn {
    margin-top: 20px;
    &--item {
      min-width: 80px;
      color: #ab0033;
      border: 1px solid #ab0033;
      margin-right: 10px;
    }
  }
}

.detail {
  display: flex;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  &__box {
    width: 393px;
    border-right: 1px solid #e5e5e5;
    &:last-of-type {
      border-right: none;
    }
    &--title {
      font-size: 14px;
      color: #222;
      background-color: #f3f3f3;
      padding-left: 20px;
      height: 40px;
      line-height: 40px;
    }
    &--content {
      padding: 20px;
    }
    &--item {
      color: #222;
      line-height: 18px;
      margin-bottom: 10px;
    }
    &--name {
      margin-left: 20px;
    }
    &--box {
      color: #222;
      display: flex;
      line-height: 18px;
      margin-bottom: 10px;
    }
    &--label {
      min-width: 60px;
    }

    &--discount {
      color: #2d89da;
      cursor: pointer;
      margin-left: 20px;
    }
    &--btn {
      display: inline-block;
      width: 40px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #999;
      color: #666;
      margin-left: 20px;
      cursor: pointer;
      &:hover {
        color: #ab0033;
        border-color: #ab0033;
      }
    }
  }
}

.table {
  border: 1px solid #e5e5e5;
  .el-table {
    font-size: 12px;
    color: #333;
    /deep/ th {
      padding: 8px 0;
      color: #333;
      background-color: #f8f8f8 !important;
      border-color: #e5e5e5;
      font-weight: normal;
    }
    /deep/ .el-table__row {
      height: 110px;
      td {
        border-bottom: 1px dashed #e5e5e5;
      }
    }
    .table__name {
      width: 338px;
      padding-left: 10px;
      &--img {
        width: 70px;
        height: 70px;
        float: left;
        margin-right: 10px;
      }
      &--name {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        width: 190px;
        line-height: 18px;
        height: 34px;
      }
      &--commoditySpecValue {
        color: #999;
        line-height: 18px;
      }
      &--rebate {
        width: 40px;
        height: 18px;
        background: #ffe8e8;
        border-radius: 2px;
        text-align: center;
        line-height: 18px;
        color: #ab0033;
        font-size: 14px;
        display: inline-block;
        margin-right: 6px;
      }
      &--price {
        color: #222;
      }
      &--allPrice {
        color: #ab0033;
        font-size: 18px;
      }
    }
  }
}

.red {
  color: #ab0033;
  border-color: #ab0033;
}

.bgRed {
  background-color: #ab0033;
  color: #fff;
}

.pointer {
  cursor: pointer;
}
</style>
